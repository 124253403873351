<template>
  <div class="registration-container" :class="{ 'registration-container_modal': modal }">

    <div v-if="step === 1">
      <p class="registration__title registration__title_center" v-if="modal">{{ 'registration' | localize }}</p>
      <p class="registration__subtitle" v-if="modal">{{ 'registration_text' | localize }}</p>

      <v-text-field
        class="input input_green"
        :label="'name' | localize"
        :placeholder="'placeHolder_for_name' | localize"
        outlined
        v-model="name"
        @input="checkName($v.name)"
        :hint="($v.name.$dirty && !$v.name.required ? 'form_reqiered' : 'empty') | localize"
        :class="{invalid:$v.name.$dirty && !$v.name.required}"
      />
      <v-text-field
        type="email"
        class="input input_green"
        :label="'form_email' | localize"
        :placeholder="'placeHolder_email' | localize"
        outlined
        v-model="email"
        @input="checkEmail($v.email)"
        :hint="hintArray(
          $v.email, [
            {rule: $v.email.required, message: 'form_reqiered_email'},
            {rule: $v.email.alpha, message: 'form_wrong_abc_email'},
            {rule: $v.email.email, message: 'form_wrong_email'}
          ]
        ) | localize"
        :class="{invalid: ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email) || ($v.email.$dirty && !$v.email.alpha)}"
      />
    </div>

    <div v-if="step === 2">
      <p class="registration__title registration__title_center">{{ 'password' | localize }}</p>
      <p class="registration__subtitle">{{ 'enter_password' | localize }}</p>
  
      <v-text-field
        tabindex="1"
        class="input input_green"
        :label="'new_password' | localize"
        outlined
        v-model="newPassword"
        @click:append="showNewPassword = !showNewPassword"
        :type="showNewPassword ? 'text' : 'password'"
        @input="checkPassword($v.newPassword)"
        :hint="getHintNewPassword($v.newPassword) | localize"
        :append-icon="showNewPassword ? 'icon-eye-on' : 'icon-eye-off-1'"
        :class="{invalid: $v.newPassword.$dirty && (!$v.newPassword.required || !$v.newPassword.minLength || !$v.newPassword.maxLength)}"
      />
      <v-text-field
        tabindex="2"
        class="input input_green"
        :label="'new_password_again' | localize"
        outlined
        ref="pass2"
        v-model="asPassword"
        :append-icon="showAsPassword ? 'icon-eye-on' : 'icon-eye-off-1'"
        @click:append="showAsPassword = !showAsPassword"
        :type="showAsPassword ? 'text' : 'password'"
        @input="checkPassword($v.asPassword)"
        @keyup.enter="passwordValid ? registration() : ''"
        :hint="($v.asPassword.$dirty && !$v.asPassword.sameAs? 'passwords_must_match' : 'empty') | localize"
        :class="{invalid: $v.asPassword.$dirty && !$v.asPassword.sameAs}"
      />
    </div>

    <div v-if="step === 3">
      <p class="registration__title registration__title_center">{{ 'reg_phone_number' | localize }}</p>
      <p class="registration__subtitle">{{ 'enter_phone' | localize }}</p>

      <v-text-field
        class="input phone v-input-phone input_green"
        type="tel"
        v-mask.hide-on-empty="phoneMask"
        :label="'reg_phone_number' | localize"
        placeholder="050 123 4567"
        outlined
        v-model="phone"
        @input="checkPhone($v.phone)"
        :hint="($v.phone.$dirty && !$v.phone.required ? 'form_reqiered_number' : 'empty') | localize"
        :class="{invalid: $v.phone.$dirty && (!$v.phone.required || !$v.phone.minLength)}"
      />
    </div>

    <div class="registration-container_agreement registration-container_agreement__position" v-if="step === 1 || step === 3">
      {{'registration_text1' | localize }}
      <router-link class="registration-container_agreement-text" :to="{ name: 'Terms of use', params: { routeName: $router.currentRoute.name, returnToLogin: true } }">{{ "terms_of_use" | localize }}</router-link>
      {{'registration_text2' | localize }}
      <router-link class="registration-container_agreement-text" :to="{ name: 'Privacy policy', params: { routeName: $router.currentRoute.name, returnToLogin: true } }">{{ "registration_text3" | localize }}</router-link>
    </div>

    <div class="error__container">
      <p class="error" v-if="error">{{ error | localize }}</p>
    </div>

    <app-button :on-click="registration" :disabled="getDisableButton" class="registration-container-button" labelBtn="problemWithService_send" />

  </div>
</template>

<script>
import AppButton from '@/components/profile/AppButton'
import { formMixin } from '@/mixins/formMixin'

const { required, email, minLength, maxLength, sameAs } = require('vuelidate/lib/validators')

export default {
  name: 'Registration',
  components: { AppButton },
  props: {
    modal: { type: Boolean, default: false },
    correctPhone: { type: Boolean, default: false }
  },
  mixins: [formMixin],
  data () {
    return {
      step: 1,
      textId: 1,

      name: null,
      email: null,
      newPassword: null,
      asPassword: null,
      phone: null,

      userId: null,
      error: null,

      phoneMask: [/0/, /5|7/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
      showNewPassword: false,
      showAsPassword: false,
      nameValid: false,
      emailValid: false,
      phoneValid: false,
      passwordValid: false
    }
  },
  created () {
    const user = this.$cookies.get('user') || {}
    this.userId = user.id || 0
    this.name = user.name || null
    this.email = user.email || null
    this.phone = user.phone || null

    this.checkName(this.$v.name)
    this.checkEmail(this.$v.email)
    this.checkPhone(this.$v.phone)

    if (this.correctPhone) this.step = 3
  },
  updated () {
    if (this.correctPhone) this.step = 3
  },
  watch: {
    correctPhone: function () {
      if (this.correctPhone) this.step = 3
    }
  },
  methods: {
    registration () {
      this.step++

      if (this.step === 3) {
        this.$store.commit('loading', true)
        this.axios.put('users/' + this.userId + '/save_password', {
          password: this.newPassword
        }).then(() => {
          const user = this.$cookies.get('user') || {}
          this.phone = user.phone || null
          this.checkPhone(this.$v.phone)
          this.$store.commit('loading', false)
        }).catch((error) => {
          this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
          this.$store.commit('loading', false)
          this.$scrollTo('.error__container', 300, { offset: -400 })
        })
      }

      if (this.step === 4) {
        this.$store.commit('loading', true)
        this.axios.put('users/' + this.userId, {
          name: this.name,
          email: this.email,
          phone: this.phone
        }).then((response) => {
          const userData = response.data || {}
          this.user = {
            id: userData.id,
            name: userData.name,
            phone: userData.phone,
            photo: userData.photo,
            email: userData.email,
            token: this.$store.state.user.token || null
          }
          this.$store.commit('saveUser', this.user)
          this.$store.commit('loading', false)
          this.step = 1
          this.$emit('confirmed')
        }).catch((error) => {
          this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
          this.$store.commit('loading', false)
          this.$scrollTo('.error__container', 300, { offset: -400 })
        })
      }
    },
    checkName (field) {
      this.checkForm(field)
      this.nameValid = field.required
    },
    checkEmail (field) {
      this.checkForm(field)
      this.emailValid = field.required && field.email
    },
    checkPassword (field) {
      this.checkForm(field)
      this.passwordValid = this.$v.newPassword.required && this.$v.newPassword.minLength && this.$v.newPassword.maxLength && this.$v.asPassword.required && this.$v.asPassword.sameAs
    },
    checkPhone (field) {
      this.checkForm(field)
      this.phoneValid = field.required && field.minLength
    },
    goregistration () {
      if (this.passwordValid) { 
        this.registration()
      }
    }
  },
  computed: {
    getHintNewPassword () {
      return (field) => {
        if (field.$dirty) {
          if (!field.required) {
            return 'form_reqiered'
          }
          if (!field.minLength || !field.maxLength) {
            return 'passwords_length'
          }
          return 'empty'
        } else {
          return 'empty'
        }
      }
    },
    getValidForm () {
      return this.nameValid && this.emailValid
    },
    getDisableButton () {
      if (this.step === 1 && !this.getValidForm) {
        return true
      } else if (this.step === 2 && !this.passwordValid) {
        return true
      } else if (this.step === 3 && !this.phoneValid) {
        return true
      } else {
        return false
      }
    }
  },
  validations: {
    name: { required },
    lastName: { required },
    email: { required, email, alpha: val => /^[a-z]/i.test(val) },
    newPassword: { required, minLength: minLength(8), maxLength: maxLength(40) },
    asPassword: { required, sameAs: sameAs('newPassword') },
    phone: { required, minLength: minLength(12) }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/default/vars.scss";
.registration-container {
  &_agreement{
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-align: left;
    color: $white;
    margin-bottom: 30px;

    &-text{
      color: $green;
      text-decoration: underline;
    }
    &__position{
      margin-top: 20px;
    }
  }
}

.registration__title,
.registration__subtitle {
  text-align: left;
  color: $white;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 30px;

  &_center {
    text-align: center;
  }
}
.registration__subtitle {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 30px;
}

#app.light-theme {
  .registration__title,
  .registration__subtitle {
    color: $black;
  }
}

#app.locale_heb {
  .registration__title,
  .registration__subtitle {
    text-align: right;
  }
  .registration-container_agreement {
    text-align: right;
  }
}

@media screen and (max-width: 1024px) {
  .registration-container_modal {
    padding: 0;
  }
}

@media screen and (min-width: 1025px) {
  .registration-container {
    input{
      width: 100%;
    }

    padding: 30px 140px;
    .registration-container-button{
      width: 100%;
    }
  }
}

</style>
