<template>
  <div class="page">
    <div class="wrapper illustration illustration_form">
      <Backlink title="registration" :backlink="backlink" />
      <div class="content">
        <div class="content__container">
          <Registration />
        </div>
      </div>
    </div>
    <PopupText />
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import PopupText from '@/components/PopupText.vue'
import Registration from '@/components/Registration'

export default {
  name: 'Registration_page',
  components: { Backlink, Registration, PopupText },
  props: {
    backlink: { type: String, default: '/' }
  }
}
</script>
